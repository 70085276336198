const _enTranslation = {
  lang_code: "EN",
  hello: "Hello",
  layout: {
    sidebar: {
      nav_items: {
        dashboard: "Dashboard",
        firm: "Firm",
        profile: "Firm Profile",
        settings: "Firm Settings",
        matters: "Matters",
        create_new_matter: "Create New Matter",
        manage_matters: "Manage Matters",
        customize_deadlines: "Customize Deadlines",
        manage_group: "Manage Group",
        copy_matter_template: "Copy Matter Template",
        naming_policy: "Naming Policies",
        manage_teams_templates: "Manage Teams Templates",
        contact: "Contact",
        manage_contacts: "Manage Contacts",
        contact_tags: "Contact Tags",
        meeting: "Meeting",
        config_meetings: "Configure Meetings",
        reports: "Reports",
        data_range_report: "Date Range Report",
        case_sharing_report: "Case Sharing Report",
        rule_change_report: "Rule Change Report",
        modified_deadline_report: "Modified Deadline Report",
        download_report: "Download All-Time Report",
        users: "Users",
        timesheet: "Timesheets",
        activity: "Activity",
        push_notifications: "Push Notifications",
        user_activity_report: "User Activity Report",
        plugins: "Manage Plugins",
        help: "Help",
        net_documents: "Net Documents",
        generate_url: "Generate URL",
        practice_area: "Practice Area",
        clause_bank: "Clause Banks",
        schedule_teams_report: "Schedule Teams Reports",
        archive_report: "Case Archive Report",
        deadline_audit: "Deadline Audit",
        microsoft_teams: "Microsoft Teams",
        manage_users: "Manage Users",
        netdocuments: "NetDocuments",
        customized_link: "Customized Link",
        view_matters: "View Matters",
        matter_tag: "Matter Tags",
        matter_archive_report: "Matter Archive Report",
        find_events: "Find Events",
        duplicate_events: "Duplicate Events",
        cancelled_events: "Cancelled Events",
        manage_graph_event: "Manage Event",
        task: "Task",
        audit_user_calander: "Audit User Calendar",
        help_and_notification: "Help & Notifications",
        app_notification: "App Notification",
        support: "Get Help",
        whats_new: "What's New",
        onboarding: "Onboarding",
        ltb_support: "Support Manual",
        rule_update: "Rule Update",
        admin_release_notes: "Admin Release Notes",
        training: "Training",
        powershell: "PowerShell",
        open_ai_setting: "Setting",
        play_ground: "Playground",
        ai: "AI",
        document_manager: "Document Manager",
        matter_report: "Matter Report",
        imanage: "iManage",
        imanage_matter: "iManage Matters",
        audit_user: "Audit User",
        audit_firm_deadlines: "Audit Firm Deadlines",
        prompt: "Prompt",
        ai_tag: "AI Tags",
        user_tags: "User Tags"
      },
    },
  },
  page: {
    help: {
      heading:
        "We want your experience with LawToolBox to be as user-friendly and helpful as possible.",
      make_private_case: {
        title: "Make an Attorneys Cases Private.",
        message:
          "Go to Settings > locate the user and check the box for “IsPrivate”. If any user in firm is marked as “private” then no user has access to that users’ matters or cases (unless those matters are expressly shared to that user) and other users cannot view reports for that user ort firm-wide reports. If any user is marked as “private” then the only users that have unlimited access to matters across the entire firm are users identified as “CanManageAdminPortal”.",
      },
      control: {
        title: "Control Who Can Access Admin Portal",
        message:
          "The ONLY users that can access the LawToolBox Firm Admin Portal are users that are designated as “global admins” in the Microsoft tenancy. If you want to further limit access to the LawToolBox Admin Portal you can go to Settings > locate the users > then select the checkbox for “CanManageAdminPortal”.",
      },
      disable_access: {
        title: "Disable User Access to LawToolBox App.",
        message:
          "Navigate to > Users then locate the user for whom you want to control their access to the LawToolBox app. If you disable a user, they will still appear on the app sharing list and any deadlines that are shared will appear on their calendar if sync to Outlook is active via Microsoft Groups. If a user is enabled they have full access.",
      },
      add_edit_user: {
        title: "Add, Edit or Remove Users from Firm.",
        message:
          "Go to Users, then follow the incredibly intuitive buttons for “Add, “Edit” and “Delete”. Once you delete a user they can’t be retrieved. If you MADE A MISTAKE when entering the users email credentials, delete the user then re-add. If you need to enter a UPN email address that is different than the Office 365 email address contact support@lawtoolbox.com.",
        options: [
          "FAQ",
          "Training Materials",
          "Video Library",
          "Features",
          "Schedule a Demo",
          "LawToolBox365 Brochure",
          "Better With Office Blog Post",
          "Download a Sales Pitch Deck",
          "Attorneys that need Rule-Based Deadlines",
          "Visit Microsoft’s WW ISV Showcase Video",
          "Read a Review",
          "Send Sample Email to Colleague",
        ],
      },
      deleting_user: {
        title: "Deleting Users.",
        steps: [
          'STEP 1: From the admin portal, admins can choose "Users" and select "delete user" for the departed user. This will release the license for other users.',
          "STEP 2: The admin will be reminded to also go to Office 365 admin portal to remove the users from all LawToolBox generated groups (as well as groups un-related to LawToolBox).We recommend doing step 2 on the same day that admin does step 1. Deleting a user will free up a license.",
        ],
      },
      configure_firm_level: {
        title: "Configure LawToolBox Functions that Appear at Firm Level.",
        message:
          "Click on Firm > Setting to turn the following features on and off: create matter,view calendar,view shared Inbox,view OneNote,view document folders,share calendar entries with people outside organization, and save emails to the group.",
      },
      configure_user_level: {
        title: "Configure LawToolBox Functions that Appear at User Level.",
        message:
          "Click on Users > then click on the user's name > Setting to turn the following features on and off: create matter,edit deadlines,delete deadlines,calculate deadlines,share matters,Save Email,Close matters,view firm matters,recieve email reminders,and more. group.",
      },
      more_message: "You can always surf through and explore information at",
      contact_us: "Contact us: support@lawtoolbox.com, 303-759-3572",
    },
  },
};

export default _enTranslation;
