import useOnClickOutside from "hooks/clickOutside";
import { ReactElement, useCallback, useRef } from "react";
import ReactDOM from "react-dom";

interface IelementInfoProps {
  elementInfo?: {
    title: string;
    message: string;
  };
  children?: ReactElement;
  hideActivator?: boolean;
}
const ElementInformation = ({
  elementInfo,
  children,
  hideActivator = false,
}: IelementInfoProps) => {
  const protalDiv = document.getElementById("avish-dropdown");
  const infoBodyRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  function showElementHandler(event: React.MouseEvent<HTMLElement>) {
    const activatorElement = event.currentTarget;
    const bodyElement = infoBodyRef.current;
    const parentElement = activatorElement.closest(
      ".element-information-parent"
    );
    const infoElement = activatorElement
      .closest(".element-information")
      ?.getBoundingClientRect();
    const parentOffset = parentElement?.getBoundingClientRect();

    if (bodyElement) {
      bodyElement["style"]["cssText"] = `display :flex;`;
      setTimeout(() => {
        bodyElement["style"]["transform"] = `translate3d(${
          parentOffset?.left! +
          infoElement?.width! -
          bodyElement.getBoundingClientRect().width
        }px, ${parentOffset?.bottom}px, 0px)`;
      }, 0);
    }
  }

  function hideElementHandler(event: React.MouseEvent<HTMLElement>) {
    const bodyElement = infoBodyRef.current;
    if (bodyElement) {
      bodyElement["style"]["cssText"] = "display :none";
    }
  }

  return (
    <div
      className={`element-information ${
        hideActivator ? "element-information__activator__hidden" : ""
      }`}
    >
      <div
        className="element-information__activator"
        onMouseEnter={showElementHandler}
        onMouseLeave={hideElementHandler}
      ></div>
      {ReactDOM.createPortal(
        <div className="element-information__body" ref={infoBodyRef}>
          <div className="element-information__body__wrapper">
            {children || (
              <>
                <div className="element-information__body__title">
                  {elementInfo?.title}
                </div>
                <div className="element-information__body__message">
                  {elementInfo?.message}
                </div>
              </>
            )}
          </div>
        </div>,
        protalDiv
      )}
    </div>
  );
};

export default ElementInformation;
