import { Reducer } from "redux";
import { SidebarActiontypes } from "./action.types";

export const initialState: sidebarState = {
  showSidebar: true,
};

const reducer: Reducer<sidebarState> = (
  state = initialState,
  action
): sidebarState => {
  switch (action.type) {
    case SidebarActiontypes.SET_SHOW_SIDEBAR: {
      return {
        ...state,
        showSidebar: action.showSidebar,
      };
    }
    default: {
      return state;
    }
  }
};

export { reducer as sidebarReducer };
