import {
  CImg,
  CSidebar,
  CSidebarBrand,
  CSidebarMinimizer,
  CSidebarNav,
  CSidebarNavDropdown,
  CSidebarNavItem
} from "@coreui/react";
import "scss/layouts/_sidebar.scss";
import navigation from "./_nav";

import { JsonComponentRenderer } from "components/jsonComponentRenderer";
import { memo, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { constants } from "script/constant";
import { toggleSidebar } from "store/sidebar/action";
import { authContext } from "auth/auth.provider";
import { rightArrow } from "script/icons";
import SidebarHelper from "./sidebarhelp";
const logo = require("assets/icons/ltb-logo.png");

const TheSidebar = () => {
  const { user, plugins, userSettings, isAIEnabled , isCreateMatterEnabled} = useContext(authContext);
  const { t } = useTranslation();
  const showSidebar = useSelector((state) => state?.["sidebar"]?.showSidebar);
  const nav = SidebarHelper({
    user,
    plugins,
    navigation,
    isAIEnabled,
    userSettings,
    isCreateMatterEnabled
  });
  const dispatch = useDispatch();
  function mapProps(props) {
    return { ...props, name: t(props.name) };
  }
  return (
    <CSidebar
      className="avish-sidebar"
      show={showSidebar}
      onShowChange={(val) => {
        if (val !== showSidebar) dispatch(toggleSidebar(val));
      }}
    >
      <CSidebarBrand className="brand" to="/">
        <div className="orgBrand">
          <CImg src={logo}></CImg>
        </div>
        <div className="brand-name__wrapper">
          <div className="brand-name">LawToolBox</div>
          <small>Admin Portal</small>
        </div>
      </CSidebarBrand>
      <CSidebarNav >
        {JsonComponentRenderer(
          nav,
          {
            CSidebarNavDropdown,
            CSidebarNavItem,
          },
          mapProps,
          0,
          constants.FIRMS_ALLOWED_TO_REPORT_SCHEDULER,
          0 //This is firmID we can use it when we have to add certain validation according to firm from ui side
        )}
        <CSidebarNavItem name="Go to App Portal" to="//app.lawtoolbox.com" target="_blank" icon={rightArrow} />

      </CSidebarNav>
      <CSidebarMinimizer className="c-d-md-down-none" />
    </CSidebar>
  );
};

export default memo(TheSidebar);
