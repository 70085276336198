import { Reducer } from "redux";
import {
  AddAppointmentActionTypes,
  matterAddAppointmentModalDataInitials,
} from "./action.types";

export const initialState: addAppointmentState = {
  matterAddAppointmentModalData: matterAddAppointmentModalDataInitials,
};

const reducer: Reducer<addAppointmentState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case AddAppointmentActionTypes.SET_ADD_APPOINTMENT_MODAL_DATA: {
      return {
        ...state,
        matterAddAppointmentModalData: {
          ...state.matterAddAppointmentModalData,
          ...action.matterAddAppointmentModalData,
        },
      };
    }
    case AddAppointmentActionTypes.RESET_ADD_APPOINTMENT_MODAL_DATA: {
      return {
        ...state,
        matterAddAppointmentModalData: {
          ...matterAddAppointmentModalDataInitials,
        },
      };
    }
    default: {
      return state;
    }
  }
};

export { reducer as AddAppointmentReducer };
