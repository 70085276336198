export enum AddAppointmentActionTypes {
  SET_ADD_APPOINTMENT_MODAL_DATA = "@@calendar/SET_ADD_APPOINTMENT_MODAL_DATA",
  RESET_ADD_APPOINTMENT_MODAL_DATA = "@@calendar/RESET_ADD_APPOINTMENT_MODAL_DATA",
}

export const matterAddAppointmentModalDataInitials: IMatterAddAppointmentModalData = {
  showModal: false,
  isAddAppointmentModalLoading: false,
  isSavingAddAppointment: false,
  matter: null,
};
