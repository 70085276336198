import axios from "axios";
import cookies from "./cookie.service";

axios.defaults.baseURL = `${process.env.REACT_APP_BASEURL}`;
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.headers.common["Access-Control-Allow-Origin"] =
  window.location.origin;
axios.defaults.headers.post["Content-Type"] = "application/json";

/**
 * Request interceptor
 * Runs every time before a request is hit
 * Checks for cookie token for ever request
 */
axios.interceptors.request.use(
  (config) => config,
  (error) => Promise.reject(error)
);

/**
 * Response interceptor
 * Runs every time after a response is received
 * handles error
 */
axios.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error) => {
    await errorHandler(error.request?.status);
    return Promise.reject(error);
  }
);

async function errorHandler(status: number) {
  switch (status) {
    case 500:
      break;
    case 401:
      cookies.delete("token");
      break;
    default:
      break;
  }
  return;
}

// function openWindow(path: string) {
//   if (window.location.pathname.includes(path)) return;
//   path = `/` + path;
//   window.open(path, "_self");
// }
