import {
  CHeader,
  CHeaderNav,
  CHeaderNavItem,
  CToggler
} from "@coreui/react";

import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import "scss/layouts/_header.scss";
import { toggleSidebar } from "store/sidebar/action";
import { HeaderDropdown } from "./index";
import AppConsentedNotify from "./appConsentedNotify";
const TheHeader = () => {


  const sidebarShow = useSelector((state) => state?.["sidebar"].showSidebar);


  const dispatch = useDispatch();

  const toggleSidebarHandler = () => {
    const val = [true, "responsive"].includes(sidebarShow)
      ? false
      : "responsive";
    dispatch(toggleSidebar(val));
  };

  const toggleSidebarMobile = () => {
    const val = [false, "responsive"].includes(sidebarShow)
      ? true
      : "responsive";
    dispatch(toggleSidebar(val));
  };

  return (
    <CHeader
      className="d-flex justify-content-between"
    >
      <CToggler
        inHeader
        className="ml-md-3 d-lg-none"
        onClick={toggleSidebarMobile}
      />
      <CToggler
        inHeader
        className="ml-3 d-md-down-none"
        onClick={toggleSidebarHandler}
      />
      <AppConsentedNotify />
      <CHeaderNav>
        {/* <CHeaderNavItem>
          <LanguageSwitcher></LanguageSwitcher>
        </CHeaderNavItem> */}
        {/* <CHeaderNavItem>
          <CHeaderNavLink to="/firm/settings">{cog}</CHeaderNavLink>
        </CHeaderNavItem> */}
        <CHeaderNavItem>
          <HeaderDropdown />
        </CHeaderNavItem>
      </CHeaderNav>
    </CHeader>
  );
};

export default memo(TheHeader);
