import { Reducer } from "redux";
import { MatterActiontypes } from "./action.types";
const matterFilterDefault: IUserMatterFilter = {
  UserID: 0,
  Email: "",
  Start: 1,
  Length: 10,
  SearchText: "",
  Filter: "usercases",
  UserPrefTimeZone:"",
};

const matterShareUsersModalDefault: ImatterShareUsersModal = {
  isOpen: false,
  isLoading: false,
  matter: null,
};

const calculateDeadlineModalDefault: ICalculateDeadlineModal = {
  isOpen: false,
  isLoading: false,
  matter: null,
  triggerDates: [] as ITriggerDates[],
  states: [] as IState[],
};

export const initialState: matterState = {
  matterFilter: matterFilterDefault,
  userMatters: [],
  userMatterPagination: { totalPages: 0, totalRecords: 0, from: 0, to: 0 },
  isUserMattersLoading: false,
  selectedMatters: [],
  matterShareUsersModal: matterShareUsersModalDefault,
  calculateDeadlineModal: calculateDeadlineModalDefault,
  calculateDeadlineSelectedTrigger: null,
  isCalculatingDeadline: false,
};

const reducer: Reducer<matterState> = (
  state = initialState,
  action
): matterState => {
  switch (action.type) {
    case MatterActiontypes.SET_USER_MATTERS: {
      return {
        ...state,
        userMatters: [...action.userMatters],
      };
    }
    case MatterActiontypes.SET_USER_MATTER_PAGINATION: {
      return {
        ...state,
        userMatterPagination: {
          ...state.userMatterPagination,
          ...action.userMatterPagination,
        },
      };
    }

    case MatterActiontypes.SET_USER_MATTER_FILTER: {
      return {
        ...state,
        matterFilter: {
          ...state.matterFilter,
          ...action.matterFilter,
        },
      };
    }

    case MatterActiontypes.SET_USER_MATTER_LOADER: {
      return {
        ...state,
        isUserMattersLoading: action.isUserMattersLoading,
      };
    }

    case MatterActiontypes.SET_SELECTED_MATTERS: {
      return {
        ...state,
        selectedMatters: [...action.selectedMatters],
      };
    }

    case MatterActiontypes.SET_MATTER_SHARE_USER_MODAL: {
      return {
        ...state,
        matterShareUsersModal: {
          ...state.matterShareUsersModal,
          ...action.matterShareUsersModal,
        },
      };
    }
    case MatterActiontypes.RESET_MATTER_SHARE_USER_MODAL: {
      return {
        ...state,
        matterShareUsersModal: matterShareUsersModalDefault,
      };
    }

    case MatterActiontypes.SET_CALCULATE_DEADLINE_MODAL: {
      return {
        ...state,
        calculateDeadlineModal: {
          ...state.calculateDeadlineModal,
          ...action.calculateDeadlineModal,
        },
      };
    }
    case MatterActiontypes.RESET_CALCULATE_DEADLINE_MODAL: {
      return {
        ...state,
        calculateDeadlineModal: calculateDeadlineModalDefault,
        calculateDeadlineSelectedTrigger: null,
      };
    }

    case MatterActiontypes.SET_CALCULATE_DEADLINE_SELECTED_TRIGGER: {
      return {
        ...state,
        calculateDeadlineSelectedTrigger:
          action.calculateDeadlineSelectedTrigger,
      };
    }

    case MatterActiontypes.SET_CALCULATING_DEADLINE_LOADER: {
      return {
        ...state,
        isCalculatingDeadline: action.isCalculatingDeadline,
      };
    }

    default: {
      return state;
    }
  }
};

export { reducer as matterReducer };
