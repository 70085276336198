export enum MatterActiontypes {
  SET_USER_MATTERS = "@@matter/SET_USER_MATTERS",
  SET_USER_MATTER_FILTER = "@@matter/SET_USER_MATTER_FILTER",
  SET_USER_MATTER_PAGINATION = "@@matter/SET_USER_MATTER_PAGINATION",
  SET_USER_MATTER_LOADER = "@@matter/SET_USER_MATTER_LOADER",
  SET_SELECTED_MATTERS = "@@matter/SET_SELECTED_MATTERS",
  SET_MATTER_SHARE_USER_MODAL = "@@matter/SET_MATTER_SHARE_USER_MODAL",
  RESET_MATTER_SHARE_USER_MODAL = "@@matter/RESET_MATTER_SHARE_USER_MODAL",
  SET_CALCULATE_DEADLINE_MODAL = "@@matter/SET_CALCULATE_DEADLINE_MODAL",
  RESET_CALCULATE_DEADLINE_MODAL = "@@matter/RESET_CALCULATE_DEADLINE_MODAL",
  SET_CALCULATE_DEADLINE_SELECTED_TRIGGER = "@@matter/SET_CALCULATE_DEADLINE_SELECTED_TRIGGER",
  SET_CALCULATING_DEADLINE_LOADER = "@@matter/SET_CALCULATING_DEADLINE_LOADER",
}
