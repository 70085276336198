// import apiUrls from "constants/Constants";
import api from "services/api.service";
import apiUrls from "services/apiUrls";

const commonApi = {
  fetchUser: function (): Promise<IAuthUser> {
    return api
      .get(apiUrls.USER_ME_URI)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        throw error;
      });
  },
  fetchPlugins : function (){
    return api.get(apiUrls.GET_ALL_PLUGINS).then((res) =>{
      return res.data;
    })
    .catch((error)=>{
      throw error;
    });
  },
  fetchSettings : function (){
    return api.get(apiUrls.GET_FIRM_SETTINGS).then((res) => {
      return res.data;
    })
    .catch((error)=>{
      throw error;
    });
  }

};

export default commonApi;
