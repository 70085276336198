import "scss/components/_loader.scss";

interface ILoaderProps {
  size?: string;
}

const Loader = ({ size = "1" }: ILoaderProps) => {
  return (
    <div className="ltb-loader">
      <svg className="ltb-loader__spinner" viewBox="0 0 50 50" height={`${size}rem`} width={`${size}rem`}>
        <circle
          className="ltb-loader__spinner__path"
          cx="25"
          cy="25"
          r="20"
          fill="none"
          strokeWidth="5"
          stroke="currentColor"
        ></circle>
      </svg>
    </div>
  );
};

export default Loader;
