import { Reducer } from "redux";
import { TagActiontypes } from "./action.types";

export const initialState: tagState = {
  matterTagModalData: {
    showModal: false,
    isMatterTagsLoading: false,
    isAddingMatterTags: false,
    matter: null,
    tags: [] as ITag[],
  },
  // matterFilter: {
  //   UserID: 0,
  //   Email: "",
  //   Start: 0,
  //   Length: 0,
  //   SearchText: "",
  //   Filter: "",
  // },
  // userMatters: [],
  // userMatterPagination: { totalPages: 0, totalRecords: 0, from: 0, to: 0 },
};

const reducer: Reducer<tagState> = (state = initialState, action) => {
  switch (action.type) {
    case TagActiontypes.SET_MATTER_TAG_MODAL_DATA: {
      return {
        ...state,
        matterTagModalData: {
          ...state.matterTagModalData,
          ...action.matterTagModalData,
        },
      };
    }
    case TagActiontypes.RESET_MATTER_TAG_MODAL_DATA: {
      return {
        ...state,
        matterTagModalData: {
          ...state.matterTagModalData,
          isMatterTagsLoading: false,
          isAddingMatterTags: false,
          showModal: false,
          matter: null,
        },
      };
    }
    default: {
      return state;
    }
  }
};

export { reducer as tagReducer };
