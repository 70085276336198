import { Text } from "@chakra-ui/layout";
import React from "react";

const LTBLoader = () => {
  return (
    <div className="splash">
      <div className="color-line"></div>
      <div className="splash-title">
        <Text fontSize="2xl"> LawToolBox - Admin Portal</Text>

        <p style={{ color: "green" }}>
        Manage all your firm settings, users, matters, templates from single portal.
        </p>
        {/* <div className="animated-loader"></div> */}
        <div className="spinner">
          {" "}
          <div className="rect1"></div> <div className="rect2"></div>{" "}
          <div className="rect3"></div> <div className="rect4"></div>{" "}
          <div className="rect5"></div>{" "}
        </div>
      </div>
    </div>
  );
};

export default LTBLoader;
