import { combineReducers } from "redux";
import { AddAppointmentReducer } from "./addAppointment/reducer";
import { calendarReducer } from "./calendar/reducer";
import { matterReducer } from "./matter/reducer";
import createTeamReducer from "./createTeam/reducer";
import { tagReducer } from "./tag/reducer";
import { CreateGroupState } from "./createGroup/types";
import createGroupReducer from "./createGroup/reducer";
import { sidebarReducer } from "./sidebar/reducer";
import auditSharingReducer from "./auditSharing/reducer";

export interface ApplicationState {
  tag: tagState;
  calendar: calendarState;
  addAppointment: addAppointmentState;
  matter: matterState;
  createTeam: CreateTeamModalState;
  createGroup: CreateGroupState;
  sidebar: sidebarState;
  auditSharing: AuditSharingState;
}

export const createRootReducer = () => {
  return combineReducers({
    tag: tagReducer,
    calendar: calendarReducer,
    addAppointment: AddAppointmentReducer,
    matter: matterReducer,
    createTeam: createTeamReducer,
    createGroup: createGroupReducer,
    sidebar: sidebarReducer,
    auditSharing: auditSharingReducer,
  });
};
