export enum CalendarActionTypes {
  SET_CALENDAR_MODAL_DATA = "@@calendar/SET_CALENDAR_MODAL_DATA",
  RESET_CALENDAR_MODAL_DATA = "@@calendar/RESET_CALENDAR_MODAL_DATA",
}

export const matterCalendarModalData: IMatterCalendarModalData = {
  showModal: false,
  isMatterEventsLoading: false,
  matter: null,
  matterEvents: [],
};
