import { Reducer } from "redux";
import { CreateTeamActions } from "./action.type";

const initialState: CreateTeamModalState = {
  createTeamModal: {
    isTeamCreating: false,
    isTemplatesFetching: false,
    showModal: false,
    templates: [],
  },
  templateId: 0,
  matterId: 0,
};
const createTeamReducer: Reducer<CreateTeamModalState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case CreateTeamActions.SET_MODAL: {
      return {
        ...state,
        createTeamModal: {
          ...state.createTeamModal,
          ...action.createTeamModal,
        },
      };
    }
    case CreateTeamActions.SET_SLECTED_MATTER_ID: {
      return {
        ...state,
        matterId: action.matterId,
      };
    }
    case CreateTeamActions.SET_SLECTED_TEMPLATE_ID: {
      return {
        ...state,
        templateId: action.templateId,
      };
    }
    default:
      return state;
  }
};
export default createTeamReducer;
