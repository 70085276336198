import { CContainer } from "@coreui/react";
import { TheSidebar, TheFooter, TheHeader } from "./index";

const NavigationLayout = ({ ...props }) => {
  return (
    <div className="c-app c-default-layout">
      <TheSidebar />
      <div className="c-wrapper">
        <TheHeader />
        <div className="c-body">
          <main className="c-main pt-2">
            <CContainer fluid className="avish-page-wrapper">
              {props.children}
            </CContainer>
          </main>
        </div>
        <TheFooter />
      </div>
    </div>
  );
};

export default NavigationLayout;
