type themeType = "default" | "teams" | "outlook" | "word";

export default function themeController(theme: themeType = "default") {
  window.onload = function get_body() {
    const root = document.querySelector(":root");

    //Removing any previous theme classes from body
    root?.classList?.forEach((elementClass) => {
      if (elementClass.includes("theme")) {
        root?.classList.remove(elementClass);
      }
    });
    root?.classList?.add(`theme-${theme}`);
  };
}
