import React from "react";
import { CFooter } from "@coreui/react";

const TheFooter = () => {
  return (
    <CFooter fixed={false}>
      <div>
        <a
          href="https://lawtoolbox.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          LawToolBox
        </a>
        <span className="ml-1">
          &copy; Inc 1998-{new Date().getFullYear()}.
        </span>
      </div>
      <div className="mfs-auto">
        <span className="mr-1"></span>
        <a href="/" target="_blank" rel="noopener noreferrer">
          LawToolBox Admin Portal
        </a>
      </div>
    </CFooter>
  );
};

export default React.memo(TheFooter);
