import { Reducer } from "redux";
import { CalendarActionTypes, matterCalendarModalData } from "./action.types";

export const initialState: calendarState = {
  matterCalendarModalData: matterCalendarModalData,
};

const reducer: Reducer<calendarState> = (state = initialState, action) => {
  switch (action.type) {
    case CalendarActionTypes.SET_CALENDAR_MODAL_DATA: {
      return {
        ...state,
        matterCalendarModalData: {
          ...state.matterCalendarModalData,
          ...action.matterCalendarModalData,
        },
      };
    }
    case CalendarActionTypes.RESET_CALENDAR_MODAL_DATA: {
      return {
        ...state,
        matterCalendarModalData: {
          ...state.matterCalendarModalData,
          isMatterTagsLoading: false,
          showModal: false,
          matter: null,
        },
      };
    }
    default: {
      return state;
    }
  }
};

export { reducer as calendarReducer };
