import { useContext } from "react";
import { constants } from "../script/constant";
import {
  CButton,
  CCol,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CImg,
  CRow,
} from "@coreui/react";
import { useEffect } from "react";
import { useState } from "react";
import { authContext } from "auth/auth.provider";
import AvishButton from "components/button/button";
import api from "services/api.service";
import { Avatar, AvatarBadge, Text } from "@chakra-ui/react";


const HeaderDropdown = () => {
  const { user } = useContext(authContext);
  const [date, setdate] = useState(new Date().getFullYear());


  const handleLogout = async () => {
    await api
      .get(constants.USER_SIGNOUT_URI)
      .then(() => {
        window.location.reload();
      })
      .catch(() => {
        window.location.reload();
      });
  };

  return (
    <CDropdown className="c-header-nav-items ">
      <CDropdownToggle className="c-header-nav-link" caret={false}>
        <div className="c-avatar">
          <CImg src={user.userImage!} className="c-avatar-img" alt="User" />
        </div>
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        {user && (
          <>
            {" "}
            <CRow className="align-items-center">
              <CCol xs="12">
                <div style={{ marginLeft: "0.5rem", marginTop: "0.5rem" }}>
                  <p className="textBold text-center"> {user.firmName}</p>
                </div>
              </CCol>

            </CRow>
            <CDropdownItem divider color="success" />
            <div className="px-2 d-flex">
              <div className="d-flex flex-column justify-content-center" >
                <Avatar name={user.userName} size="md" >
                  <AvatarBadge boxSize='1em' bg='green.500'  />
                </Avatar>
              </div>
              <div className="px-2 d-flex flex-column justify-content-center">
                <div>
                  <p className="small"><strong>{user.userName}</strong></p>
                  <p className="small">{user.userEmail}</p>
                   <p className="small" style={{ fontWeight: 450 }}>{user.userTimeZoneName}{user.userTimeZoneName &&","}{user.stateName} </p> 
                </div>
              </div>
              <div className="d-flex flex-column justify-content-center">
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <button className="bg-success px-2 badge" style={{ height: "16px" }}>Admin</button>
                  {/* {user.isDocketingUser && (
                    <button className="bg-danger px-2 mt-1 badge" style={{ height: "16px" }}>Docketing User</button>
                  )} */}
                </div>
              </div>
              {/*<CCol>*/}

              {/*  <div style={{ marginLeft: "0.5rem" }}>*/}
              {/*    <p>{user.userName}</p>*/}
              {/*    <p>{user.userEmail}</p>*/}
              {/*    <span>{user.userPrefTimeZone} ,{user.stateAbr}</span>*/}

              {/*  </div>*/}
              {/*</CCol>*/}
            </div>

          </>
        )}
        <CDropdownItem divider color="success" />

        <div style={{marginLeft: "1rem" }} className="text-info">
          <a
            href="https://teams.microsoft.com/l/app/3ee373aa-62fa-4fc6-b11f-9627d5b4a73d?source=app-details-dialog"
            target="_blank"
            rel="noreferrer"
          >
            <small>Microsoft Team App</small>
          </a>
          <br />
          <a
            href="https://app.lawtoolbox.com"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            <small> App in Browser</small>
          </a>
          <br />
        </div>

        <CDropdownItem divider />
        <CRow className="align-items-center">
          <CCol xs="12">
            <div className="text-center text-info">
              <a
                href=" https://www.linkedin.com/groups/9048766/"
                target="_blank"
                rel="noreferrer"
              > <small>Join LawToolBox Community</small></a>
            </div>
          </CCol>
        </CRow>
        <CDropdownItem divider />
        <div className="d-flex flex-column">
          <div className="text-center text-info">
            {" "}
            <div className="justify-content-end">
              <a
                href=" https://lawtoolbox.freshdesk.com/support/solutions/16000061277"
                target="_blank"
                rel="noreferrer"
              > <small>Support FAQ</small></a>&nbsp; |&nbsp;
              <a
                href=" https://lawtoolbox.freshdesk.com/support/tickets/new"
                target="_blank"
                rel="noreferrer"
                style={{ border: "0px .04px #ccc" }}
              > <small>Open a Ticket</small></a>&nbsp;|&nbsp;
              <a
                href="mailto:support@lawtoolbox.com"
              > <small>Send Feedback</small></a>
            </div>
          </div>
          {/* <div className="text-center font-xs">
            © {date} LawToolBox.com, Inc.
          </div> */}
        </div>
        <CDropdownItem divider />
        <div className="">
          <div className="float-right">
            {" "}
            <div className="justify-content-end">
              <AvishButton
                color="primary"
                value="Sign Out"
                type="button"
                parentClassName="mr-2"
                onClick={() => {
                  handleLogout();
                }}
              ></AvishButton>
            </div>
          </div>
          {/* <div className="text-center font-xs">
            © {date} LawToolBox.com, Inc.
          </div> */}
        </div>
      </CDropdownMenu>
    </CDropdown>
  );
};

export default HeaderDropdown;
