import {
  pdf as pdfIcon,
  file as fileIcon,
  image as imageIcon,
  xls as xlsIcon,
  doc as docIcon,
  zipFlie,
} from "script/icons";

const helper = {
  Base64ToBytes: function (base64) {
    var s = window.atob(base64);
    var bytes = new Uint8Array(s.length);
    for (var i = 0; i < s.length; i++) {
      bytes[i] = s.charCodeAt(i);
    }
    return bytes;
  },
  convertMinutesToHoursAndMinutes: function (totalMinutes) {
    // Calculate hours and remaining minutes
    var hours = Math.floor(totalMinutes / 60);
    var minutes = totalMinutes % 60;

    // Create a readable string
    var hoursStr = (hours === 1) ? '1 hour' : hours + ' hours';
    var minutesStr = (minutes === 1) ? '1 minute' : minutes + ' minutes';

    // Combine hours and minutes
    var result = (hours === 0) ? minutesStr :
      (minutes === 0) ? hoursStr :
        hoursStr + ' and ' + minutesStr;

    return result;
  },
  getCurrentDate: function (separator = '') {

    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    let hour = newDate.getHours();
    let min = newDate.getMinutes();
    let sec = newDate.getSeconds();

    return `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date}${separator}${hour}${separator}${min}${separator}${sec}`
  },
  calculateDateDifferenceInMonths: function (startDate, endDate) {
    const start = new Date(startDate);
    const end = new Date(endDate);

    const yearDiff = end.getUTCFullYear() - start.getUTCFullYear();
    const monthDiff = end.getUTCMonth() - start.getUTCMonth();

    return yearDiff * 12 + monthDiff;
  },
  nameAvatar: function (name: string) {
    name = name || "";

    var nameSplit = String(name).toUpperCase().split(" "),
      initials,
      charIndex,
      colourIndex,
      color;

    if (nameSplit.length == 1) {
      if (nameSplit[0].length > 1) {
        initials =
          nameSplit[0].charAt(0) + nameSplit[0].charAt(1).toLowerCase();
      } else initials = nameSplit[0] ? nameSplit[0].charAt(0) : "?";
    } else {
      initials = nameSplit[0].charAt(0) + nameSplit[1].charAt(0);
    }

    charIndex =
      (initials == "?"
        ? 72
        : Math.round(
          (initials.charCodeAt(0) + initials.charCodeAt(1) || 0) / 2
        )) - 64;
    colourIndex = (charIndex % 20) * 18;
    color = `hsl(${colourIndex}deg, 57%, 78%)`;
    return { initials, color };
  },

  getFormattedDate: function (date: string | Date) {
    const newDate = new Date(date);
    const year = newDate.getFullYear();
    const newMonth = newDate.getMonth() + 1;
    const month = newMonth < 10 ? `0${newMonth}` : newMonth;
    const day =
      newDate.getDate() < 10 ? `0${newDate.getDate()}` : newDate.getDate();

    return `${month}/${day}/${year}`;
  },
  getFormattedDateDashedMMDDYY: function (date: string | Date) {
    const newDate = new Date(date);
    const year = newDate.getFullYear();
    const newMonth = newDate.getMonth() + 1;
    const month = newMonth < 10 ? `0${newMonth}` : newMonth;
    const day =
      newDate.getDate() < 10 ? `0${newDate.getDate()}` : newDate.getDate();

    return `${month}-${day}-${year}`;
  },

  getCurrentMonthAsIntegerValue: function () {
    const d = new Date();
    let month = d.getMonth() + 1;
    return month;
  },

  getUserReadableDateAndTime(date: string) {
    if (!date || date.length === 0) return "Invalid Date";
    const newDate = new Date(date);
    const month = newDate.toLocaleString("default", { month: "short" });
    const year = newDate.getFullYear();
    const day = newDate.getDate();

    return `${this.getNthStringOfNumber(
      day
    )} ${month} ${year}, ${this.get12HoureTimeFormat(date)}`;
  },
  getUserReadableDate(date: string) {
    if (!date || date.length === 0) return "Invalid Date";
    const newDate = new Date(date);
    const month = newDate.toLocaleString("default", { month: "short" });
    const year = newDate.getFullYear();
    const day = newDate.getDate();

    return `${this.getNthStringOfNumber(
      day
    )} ${month} ${year}`;
  },

  dateDiff: {
    inDays: function (d1, d2) {
      var t2 = d2.getTime();
      var t1 = d1.getTime();

      return Math.floor((t2 - t1) / (24 * 3600 * 1000));
    },

    inWeeks: function (d1, d2) {
      var t2 = d2.getTime();
      var t1 = d1.getTime();

      return (t2 - t1) / (24 * 3600 * 1000 * 7);
    },

    inMonths: function (d1, d2) {
      var d1Y = d1.getFullYear();
      var d2Y = d2.getFullYear();
      var d1M = d1.getMonth();
      var d2M = d2.getMonth();

      return d2M + 12 * d2Y - (d1M + 12 * d1Y);
    },

    inYears: function (d1, d2) {
      return d2.getFullYear() - d1.getFullYear();
    },

    getDifference(d1: Date, d2 = new Date()) {
      if (this.inDays(d1, d2) === 0) return "today";
      if (this.inYears(d1, d2) > 1) return this.inYears(d1, d2) + " years ago";
      else if (this.inMonths(d1, d2) >= 2)
        return this.inMonths(d1, d2) + " months ago";
      else return this.inDays(d1, d2) + " days ago";
    },
    getRemainingDaysFromToday(d1: Date, d2 = new Date()) {
      if (this.inDays(d1, d2) < 0) return Math.abs(this.inDays(d1, d2)) + " days from today";
    },
  },



  get12HoureTimeFormat(date: string) {
    if (!date || date.length === 0) return "Invalid Date";
    const newDate = new Date(date);
    return new Intl.DateTimeFormat("default", {
      hour12: true,
      hour: "numeric",
      minute: "numeric",
    }).format(newDate);
  },
  getNthStringOfNumber(num: number) {
    const dString = String(num);
    const last = +dString.slice(-2);
    if (last > 3 && last < 21) return `${dString}th`;
    switch (last % 10) {
      case 1:
        return `${dString}st`;
      case 2:
        return `${dString}nd`;
      case 3:
        return `${dString}rd`;
      default:
        return `${dString}th`;
    }
  },

  getFileExtension: function (file: string) {
    return /[.]/.exec(file) ? /[^.]+$/.exec(file) : undefined;
  },

  getExtensionIcon: function (fileName: string) {
    const extension = this.getFileExtension(fileName)?.toString();
    switch (extension) {
      case "pdf":
        return pdfIcon;
      case "xlsx":
      case "xls":
        return xlsIcon;
      case "png":
      case "jpg":
      case "jpeg":
      case "svg":
        return imageIcon;
      case "doc":
      case "docx":
        return docIcon;
      case "zip":
        return zipFlie;
      default:
        return fileIcon;
    }
  },

  checkforImage(fileName: string) {
    const extension = this.getFileExtension(fileName)?.toString();
    return ["png", "jpg", "jpeg", "svg"].includes(extension!);
  },

  filterDeadlines: function (deadlines: IDeadline[], filter = "all") {
    const today = new Date();
    switch (filter) {
      case "past": {
        deadlines = deadlines.filter(
          (deadline) => new Date(deadline.startDate) < today
        );
        break;
      }
      case "future": {
        deadlines = deadlines.filter(
          (deadline) => new Date(deadline.startDate) >= today
        );
        break;
      }
    }
    return deadlines;
  },

  evaluateDeadlineTitle: function (deadline: IDeadline) {
    const startDate = new Date(deadline.startDate);
    const daysInWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    var time =
      deadline.startTime || deadline.endTime
        ? ` | ${deadline.startTime} - ${deadline.endTime} (${deadline.timeZone})`
        : "[All Day]";
    return `${deadline.startDate}(${daysInWeek[startDate.getDay()]}) ${time}`;
  },

  searchDeadline: function (deadlines, search) {
    return deadlines.filter(
      (deadline) =>
        helper
          .evaluateDeadlineTitle(deadline)
          .toLowerCase()
          .includes(search.toLowerCase()) ||
        helper
          .evaluateDeadlineType(deadline)
          .appointmentType.toLowerCase()
          .includes(search.toLowerCase()) ||
        deadline.description_Short.toLowerCase().includes(search.toLowerCase())
    );
  },

  evaluateDeadlineType: function (deadline: IDeadline) {
    var appointmentType = "Deadline",
      lblAppointmentType = "Deadline";
    if (
      !deadline.isTriggerDate &&
      deadline.deadlineNumber.toString().includes("T")
    ) {
      lblAppointmentType = deadline.deadlineType.toUpperCase().includes("E")
        ? "Appointment"
        : "Deadline";
      appointmentType = "Appointment";
    } else if (deadline.isTriggerDate) {
      appointmentType = "TriggerDate";
      lblAppointmentType = "TriggerDate";
    }
    return { appointmentType, lblAppointmentType };
  },

  getMonthTimelineTitle: function (deadline: IDeadline) {
    const startDate = new Date(deadline.startDate);
    const month = startDate.toLocaleString("default", { month: "short" });
    const year = startDate.getFullYear();
    return `${month} ${year}`;
  },

  getMonthsMappedTimeline: function (deadlines: IDeadline[], filter = "all") {
    let timelines: ITimelineType[] = [];

    deadlines = this.filterDeadlines(deadlines, filter);

    timelines = deadlines.reduce((arr, deadline) => {
      const timelineTitle = this.getMonthTimelineTitle(deadline);
      if (arr.length > 0)
        arr.forEach((timeline, index) => {
          if (timeline.title.toLowerCase() === timelineTitle.toLowerCase()) {
            arr[index].deadlines.push(deadline);
          } else {
            if (arr.length - 1 === index) {
              arr.push({
                title: timelineTitle,
                deadlines: [deadline],
              });
            }
          }
        });
      else {
        arr.push({
          title: timelineTitle,
          deadlines: [deadline],
        });
      }
      return arr;
    }, [] as ITimelineType[]);

    return timelines.reverse();
  },

  getTriggerDateMappedTimeline: function (
    deadlines: IDeadline[],
    filter = "all"
  ) {
    let timelines: ITimelineType[] = [];
    deadlines = this.filterDeadlines(deadlines, filter);

    timelines = deadlines.reduce((arr, deadline) => {
      if (
        this.evaluateDeadlineType(deadline).appointmentType === "Appointment"
      ) {
        let timelineIndex = arr.findIndex((el) => el.title === "Appointment");
        if (timelineIndex >= 0) {
          arr[timelineIndex].deadlines.push(deadline);
        } else {
          arr.push({
            title: "Appointment",
            deadlines: [deadline],
          });
        }
      } else {
        let timelineIndex = arr.findIndex(
          (el) =>
            el.deadlines.some(
              (el) => el.parentTriggerID === deadline.parentTriggerID
            ) && el.title !== "Appointment"
        );
        if (timelineIndex >= 0) {
          arr[timelineIndex].deadlines.push(deadline);
        } else {
          arr.push({
            title: deadline.description_Short,
            deadlines: [deadline],
          });
        }
      }
      return arr;
    }, [] as ITimelineType[]);

    return timelines.reverse();
  },
  getTriggerDateMappedTimelineByDeadlineId: function (
    deadlines: IDeadline[],
    filter = "all",
    deadlineID: string,
  ) {
    let timelines: ITimelineType[] = [];
    let filteredDeadlines: IDeadline[] = [];
    deadlines = this.filterDeadlines(deadlines, filter);
    filteredDeadlines = deadlines.filter((deadline) => deadline?.parentTriggerID === deadlineID);
    timelines = filteredDeadlines.reduce((arr, deadline) => {
      if (
        this.evaluateDeadlineType(deadline).appointmentType === "Appointment"
      ) {
        let timelineIndex = arr.findIndex((el) => el.title === "Appointment");
        if (timelineIndex >= 0) {
          arr[timelineIndex].deadlines.push(deadline);
        } else {
          arr.push({
            title: "Appointment",
            deadlines: [deadline],
          });
        }
      } else {
        let timelineIndex = arr.findIndex(
          (el) =>
            el.deadlines.some(
              (el) => el.parentTriggerID === deadline.parentTriggerID
            ) && el.title !== "Appointment"
        );
        if (timelineIndex >= 0) {
          arr[timelineIndex].deadlines.push(deadline);
        } else {
          arr.push({
            title: deadline.description_Short,
            deadlines: [deadline],
          });
        }
      }
      return arr;
    }, [] as ITimelineType[]);

    return timelines.reverse();
  },

  copyToClipboard: function (elementId: string, text: string) {
    if (!elementId.length) return;
    var aux = document.createElement("input");
    var copiedText = document.createElement("span");
    copiedText.innerText = "Copied!!";
    copiedText.classList.add("copy-text");
    var parent = document.getElementById(elementId);
    parent?.classList.add("copy-text-parent");
    parent?.append(copiedText);

    aux.setAttribute("value", text);
    document.body.appendChild(aux);
    aux.select();
    document.execCommand("copy");
    document.body.removeChild(aux);

    setTimeout(function () {
      parent?.removeChild(copiedText);
    }, 750);
  },

  getArrayOfObjectKey(arr, key) {
    return arr?.reduce((arr, el) => {
      arr.push(el?.[key]);
      return arr;
    }, []);
  },

  isObject(data) {
    if (typeof data === "object" && data !== null && !Array.isArray(data))
      return true;
    return false;
  },

  getFormDataObject(event) {
    let formData = {};
    for (const element of event?.target) {
      if (element.name.length === 0) continue;
      if (element.type === "checkbox") {
        formData[element.name] = element.checked;
      } else if (
        element.type === "date" &&
        element.value &&
        element.value.length > 0
      ) {
        formData[element.name] = helper.getFormattedDate(element.value);
      } else if (element.value && element.value.length > 0) {
        formData[element.name] = element.value;
      }
    }
    return formData;
  },

  getPlaneFormDataObject(event) {
    let formData = {};
    for (const element of event.target) {
      if (element.name.length === 0) continue;
      if (element.type === "checkbox") {
        formData[element.name] = element.checked;
      } else if (element.value && element.value.length > 0) {
        formData[element.name] = element.value;
      }
    }
    return formData;
  },

  formatAMPM(date) {
    date = new Date(date);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? (hours < 10 ? `0${hours}` : hours) : 12;
    minutes = minutes.toString().padStart(2, "0");
    let strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  },

  getTime(date) {
    date = new Date(date);
    let hours = date.getHours();
    hours = hours < 10 ? `0${hours}` : hours;
    let minutes = date.getMinutes();
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    return hours + ":" + minutes;
  },

  checkIfObjectAreEqual(obj1, obj2) {
    if (!obj1 || !obj2) return false;
    const obj1Length = Object.keys(obj1).length;
    const obj2Length = Object.keys(obj2).length;

    if (obj1Length === obj2Length) {
      return Object.keys(obj1).every(
        (key) => obj2.hasOwnProperty(key) && obj2[key] === obj1[key]
      );
    }
    return false;
  },

  setDropdownPosition(
    controlRef,
    dropdownRef,
    dropdownDimensions: {
      maxHeight: number;
      minWidth: number;
      position?: "left" | "right";
    } = { maxHeight: 300, minWidth: 300, position: "left" }
  ) {
    if (!controlRef) return;
    const pos = controlRef.current.getBoundingClientRect();
    const vh = Math.max(
      document.documentElement.clientHeight || 0,
      window.innerHeight || 0
    ),
      vw = Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0
      );

    setTimeout(() => {
      if (controlRef?.current === null || dropdownRef?.current === null) return;
      var options = dropdownRef?.current,
        optionsDimension = options?.getBoundingClientRect();
      var left = pos.left;

      var topAvailableHeight = pos.y,
        bottomAvailableHeight = vh - pos.bottom,
        leftAvailableWidth = pos.x + pos.width,
        rightAvailableWidth = vw - pos.x;

      if (
        dropdownDimensions.position === "right" &&
        leftAvailableWidth > optionsDimension.width
      ) {
        left = pos.left + pos.width - optionsDimension.width;
      }

      options.style.inset = "0px auto auto 0px";
      options.style.transform = `translate3d(${left}px, ${pos.bottom}px, 0px)`;
      options.classList.remove("top-shadow");
      options.classList.remove("dropdown-overlay");

      //make dropdown width same size as the control's width
      if (pos.width > dropdownDimensions.minWidth) {
        options.style.width = `${Math.floor(pos.width)}px`;
      }

      const noAvailableVerticalSpace =
        optionsDimension.height > topAvailableHeight &&
        optionsDimension.height > bottomAvailableHeight;
      const noAvailableHorizontalStace =
        optionsDimension.width > leftAvailableWidth &&
        optionsDimension.width > rightAvailableWidth;

      if (noAvailableVerticalSpace || noAvailableHorizontalStace) {
        options.style.transform = `translate3d(0px, 0px, 0px)`;
        options.classList.add("dropdown-overlay");
      } else if (!this.isElementInViewport(options)) {
        if (optionsDimension.height > bottomAvailableHeight) {
          options.style.transform = `translate3d(${left}px, ${pos.top - optionsDimension.height
            }px, 0px)`;
          options.classList.add("top-shadow");
        }
      }
    }, 0);
  },

  //Check where selected matter hasGroup or not.
  checkIfGroupIsValidForSelectedMatters(selectedMatters: IMatter[]) {
    return selectedMatters.every(matter => matter.hasGroup === true);
  },
  isElementInViewport(element) {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  },

  ImagePreview(
    file: File,
    setValue: React.Dispatch<React.SetStateAction<(string | null)[]>>
  ) {
    if (!this.checkforImage(file.name)) {
      setValue((values) => [...values, null]);
      return;
    }
    var reader = new FileReader();
    reader.onloadend = function () {
      let res = reader.result;
      setValue((values) => [...values, res ? (res as string) : null]);
    };
    reader.readAsDataURL(file);
  },
  convertToHtml: function (message) {
    // Split the message by triple backticks to identify code blocks
    const sections = message?.split('```');

    // Process each section, alternating between code and non-code
    const formattedSections = sections?.map((section, index) => {
      // Use <code> tags for code sections
      if (index % 2 === 1) {
        return `<pre><code>${section}</code></pre>`;
      }

      // Replace newlines with <p> tags for non-code sections
      const formattedText = section?.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
      const boldText = formattedText?.replace(/`([^`]+)`/g, '<code>$1</code>');

      // Replace links with <a> tags that open in a new tab (_blank)
      const linkedText = boldText?.replace(/\[(.*?)\]\((.*?)\)/g, '<a href="$2" target="_blank">$1</a>');

      return `<p>${linkedText?.replace(/\n/g, '<br>')}</p>`;
    });

    // Join the sections to form the final HTML
    const htmlString = formattedSections?.join('');

    return htmlString;
  },

  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  },
  deadlineFilterLabelForApi: function (filter = "all") {
    switch (filter) {
      case "all": {
        return "alldeadlines";
        break;
      }
      case "future": {
        return "futuredeadlines";
        break;
      }
      case "past": {
        return "pastdeadlines";
        break;
      }
      default: {
        return "";
        break;
      }
    }
  },

};

export default helper;
