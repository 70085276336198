import { createElement } from "react";
import helper from "script/helperFunctions";

const ElementCreator = (
  config,
  components,
  mapProps,
  index,
  allowedFirms,
  firmId
) => {
  const { _tag, _children, ...props } = config;
  return createElement(
    components[_tag],
    { ...mapProps(props), key: index },
    _children
      ? typeof _children === "string"
        ? _children
        : _children.map((c, key) =>
          JsonComponentRenderer(
            c,
            components,
            mapProps,
            key,
            allowedFirms,
            firmId
          )
        )
      : null
  );
}

export function JsonComponentRenderer(
  config,
  components,
  mapProps,
  key,
  allowedFirms,
  firmId
) {
  if (typeof components[config] !== undefined)
    if (Array.isArray(config)) {
      return config.map((c, index) =>
        ElementCreator(c, components, mapProps, index, allowedFirms, firmId)
      );
    } else if (helper.isObject(config)) {
      return ElementCreator(
        config,
        components,
        mapProps,
        key,
        allowedFirms,
        firmId
      );
    }
  return <div>Invalid configuration</div>;
}