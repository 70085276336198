import { BrowserRouter, Switch } from "react-router-dom";
import "./scss/style.scss";
import "./content/style.css";
import AuthProvider from "auth/auth.provider";
import routes from "routes/routes";
import PrivateRoute from "routes/route.private";
import PublicRoute from "routes/route.public";
import ToasterProvider from "components/toast/toast";

const App: React.FC = () => {
  return (
    <AuthProvider>
      <ToasterProvider>
          <BrowserRouter>
            <Switch>
              {routes.map((route, index) => {
                if (route.isProtected)
                  return <PrivateRoute key={index} {...route}></PrivateRoute>;
                else return <PublicRoute key={index} {...route}></PublicRoute>;
              })}
            </Switch>
          </BrowserRouter>
      </ToasterProvider>
    </AuthProvider>
  );
};

export default App;
