import ElementInformation from "components/elementInformation/elementInformation";
import { ReactNode } from "react";
import Loader from "views/widgets/loader";

interface IButtonProps extends React.ComponentProps<"button"> {
  value: string;
  isButtonLoading?: boolean;
  isButtonDisabled?: boolean;
  prefixIcon?: ReactNode;
  suffixIcon?: ReactNode;
  elementInfo?: {
    title: string;
    message: string;
  };
  fontSize?: string;
  parentClassName?: string;
  tooltipText?: string;
}

const AvishButton = ({
  value,
  isButtonLoading = false,
  isButtonDisabled = false,
  className = "",
  prefixIcon,
  suffixIcon,
  onClick = () => {},
  elementInfo,
  color,
  type,
  fontSize = "1rem",
  title,
  id,
  parentClassName,
  tooltipText,
  ...props
}: IButtonProps) => {
  var buttonElementClasses = [
    "avish-button",
    className,
    isButtonLoading ? "loading" : "",
    color,
    isButtonDisabled || isButtonLoading ? "disabled" : "",
  ];

  function onClickHandler(e) {
    if (isButtonDisabled || isButtonLoading) return;
    onClick(e);
  }

  return (
    <div
      className={`avish-button__wrapper position-relative d-inline-flex element-information-parent ${
        parentClassName || ""
      } ${tooltipText ? "avish-tooltip" : ""}`}
      id={id}
      style={{ fontSize }}
      data-tooltip={tooltipText}
    >
      <button
        title={title}
        type={type}
        className={buttonElementClasses.join(" ").trim()}
        disabled={isButtonDisabled}
        onClick={onClickHandler}
        {...props}
      >
        {prefixIcon && <div className="avish-button__prefix">{prefixIcon}</div>}
        {value}
        {suffixIcon && <div className="avish-button__suffix">{suffixIcon}</div>}
        {isButtonLoading && <Loader></Loader>}
      </button>
      {elementInfo && (
        <ElementInformation elementInfo={elementInfo}></ElementInformation>
      )}
    </div>
  );
};
export default AvishButton;
