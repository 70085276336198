import { Reducer } from "redux";
import AuditSharingActionTypes from "./action.type";
const auditSharingInitialState: AuditSharingState = {
  auditSharingModalConfig: {
    showModal: false,
    isLoading: false,
  },
  matterId: 0,
  auditResponse: {
    ltbUsers: [],
    users365: [],
  },
};
const auditSharingReducer: Reducer<AuditSharingState> = (
  state = auditSharingInitialState,
  action
): AuditSharingState => {
  switch (action.type) {
    case AuditSharingActionTypes.SET_MODAL:
      return {
        ...state,
        auditSharingModalConfig: {
          ...state.auditSharingModalConfig,
          ...action.auditSharingModalConfig,
        },
      };
    case AuditSharingActionTypes.SET_SLECTED_MATTER_ID: {
      return {
        ...state,
        matterId: action.matterId,
      };
    }
    case AuditSharingActionTypes.SET_GROUP_OWNERS_MEMBERS: {
      return {
        ...state,
        auditResponse: { ...action.auditResponse },
      };
    }
    default:
      return state;
  }
};
export default auditSharingReducer;
