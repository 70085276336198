import { authContext } from "auth/auth.provider";
import { useContext, useEffect, useState } from "react";

const AppConsentedNotify = () => {
    const { appPermission } = useContext(authContext);
    const [isPermission, setPermission] = useState(false);

    useEffect(() => {
        if(appPermission){
            if (!appPermission?.isMSGraphAppOnlyConsented || !appPermission.isMSGraphAppOnlyConsented) {
                setPermission(true);
            }
        }
       
    }, [appPermission])

    return (
        <>
            {isPermission && (
                <div className="alert alert-danger mt-3 py-1" role="alert">
                    <a
                        href="https://login.microsoftonline.com/common/adminconsent?client_id=2fa142be-31b5-4fda-bd37-591541b501aa&redirect_uri=https://app.lawtoolbox.com/admin/apponlyconsent"
                        target="_blank"
                        className="link"
                        rel="noreferrer"
                    >
                        Please click here to give enterprise permission or if you are not global admin copy and send the link to your admin
                    </a>
                </div>
            )}

        </>
    )
}

export default AppConsentedNotify;
