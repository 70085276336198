import { Reducer } from "redux";
import { CreateGroupActionTypes } from "./action.type";
import { CreateGroupState } from "./types";

const createGroupInitialState: CreateGroupState = {
  createGroupModalConfig: {
    isGroupCreating: false,
    matter: null,
    showModal: false,
    isGroupSearching: false,
    showGroupUserConfig: false,
  },
};
const createGroupReducer: Reducer<CreateGroupState> = (
  state = createGroupInitialState,
  { type, createGroupModalConfig }
): CreateGroupState => {
  switch (type) {
    case CreateGroupActionTypes.SET_MODAL:
      return {
        ...state,
        createGroupModalConfig: {
          ...state.createGroupModalConfig,
          ...createGroupModalConfig,
        },
      };
    default:
      return state;
  }
};
export default createGroupReducer;
