import "react-app-polyfill/ie11"; // For IE 11 support
import "react-app-polyfill/stable";
import "core-js";
import "polyfill";
import ReactDOM from "react-dom";
import App from "./App";
import "./util/i18n";

import { ChakraProvider } from "@chakra-ui/react";
import React from "react";

import { Provider } from "react-redux";
import { configureStore } from "configureStore";
import themeController from "themeController";

const initialState: any = {};
const store = configureStore(initialState);

themeController("teams");

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ChakraProvider>
        <App />
      </ChakraProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
